<template>
  <div class="aidClassification">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'aidClassification'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane
              label="助学分类"
              name="first"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <div class="table_sList">
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            lazy
            row-key="id"
            :load="loadEvent"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          >
            <el-table-column
              prop="assortName"
              label="助学分类"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="专业数"
              align="center"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="jumpAidMajor(scope.row)" v-if="$isShowModle('sys_major_query')">{{scope.row.majorCount}}</el-button>
                <span v-else>{{scope.row.majorCount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="班级数"
              align="center"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="jumpAidClass(scope.row)" v-if="$isShowModle('tch_aid_class_query')">{{scope.row.classCount}}</el-button>
                <span v-else>{{scope.row.classCount}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import publicChildren from "../publicChildren";
export default {
  name: 'aidClassification',
  components: {
    publicChildren
  },
  data() {
    return {
      activeName: 'first',
      tableData: [],
      formInline: {
        eduCategory: null,
        type: null
      },
      isFresh: true
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.name == "aidClassification") {
          // console.log(this.tableData,77)
          this.formInline.type = null;
          this.formInline.eduCategory = null;
          if(!this.isFresh) return;
          this.getAidClassificationData().then(res=>{
            this.tableData = res;
          });
        }
      },
    },
  },
  methods: {
    getAidClassificationData() {
      return new Promise((resolve, reject) => {
        this.$request.aidClassificationData(this.formInline).then(res => {
          if(res.data.code == 0){
            res.data.data.forEach((item, index) => {
              item.hasChildren = true
              item.id = item.assortValue
              item.label = 1
            })
            resolve(res.data.data);
          }
        })
      })
    },
    async loadEvent(tree, treeNode, resolve) {
      // console.log(tree, treeNode)
      if(tree.id.length == 1) {
        this.formInline.type = 1;
        this.formInline.eduCategory = null;
      } else if(tree.id.length == 2) {
        this.formInline.eduCategory = tree.assortValue;
      }
      let arr = await this.getAidClassificationData();
      arr.forEach(item => {
        item.id = tree.id + item.assortValue;
        if(tree.id.length == 2) delete item.hasChildren;
        item.label = tree.id.length == 1 ? 2 : 3;
      })
      setTimeout(() => {
        resolve(arr)
      }, 500)
    },
    jumpAidMajor(row) {
      let query = {
        eduCategory: null,
        assortName: null
      }
      if(row.label == 2) {
        query.eduCategory = row.assortValue
      } else if(row.label == 3) {
        query.eduCategory = row.id[1]
        query.directoryName = row.assortName
      }
      this.$router.push({name: 'majorNumDetails', query})
    },
    jumpAidClass(row) {
      // console.log(row)
      // return;
      let query = {
        type: row.id[0],
        eduCategory: null,
        subjectCode: null
      }
      if(row.label == 2) {
        query.eduCategory = row.id[1]
      } else if(row.label == 3) {
        query.eduCategory = row.id[1]
        query.subjectCode = row.assortValue
      }
      this.isFresh = false
      // console.log(this.tableData,211)
      this.$router.push({name: 'classNumDetails', query})
    }
  },
}
</script>

<style lang="less">
  .aidClassification {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
    }
    .table_sList {
      box-sizing: border-box;
      padding: 0 40px 30px;
      // margin-top: 10px;
      background-color: #fff;
      // ::-webkit-scrollbar {
      //   width: 8px;
      //   height: 8px;
      //   background-color: #fff;
      // }
      // ::-webkit-scrollbar-thumb {
      //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      //     background-color: rgba(0, 0, 0, .1);
      //     border-radius: 3px;
      //     border-top-right-radius: 4px;
      //     border-bottom-right-radius: 4px;
      // }
      // .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      //     border-right: none;
      // }
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
  }
</style>